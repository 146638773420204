import React from "react";
import {connect} from "react-redux";

class GetResourceButton extends React.Component {
  render() {
    return (
      <div>
        <button 
          className="btn btn-sm btn-primary"
          onClick={() => this.props.dispatch({type: this.props.addResource, add: this.props.addResourceDefault})}
        >
          Produce {this.props.text}
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    addResourceDefault: state.resources.addResourceDefault,
  }
}

export default connect(mapStateToProps)(GetResourceButton);
