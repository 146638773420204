import React from "react";
import {connect} from "react-redux";

var numeral = require("numeral"); //http://numeraljs.com/

class Technology extends React.Component {
  render() {
    if ( this.props.cost1 > this.props.resource1 || this.props.cost2 > this.props.resource2 ) {
      var techButton = <button disabled className="btn btn-sm btn-secondary">{this.props.text}</button>;
    } else {
      // eslint-disable-next-line
      var techButton = <button className="btn btn-sm btn-primary" onClick={() => {this.props.researchTech();}}>{this.props.text}</button>;
    }

    if (this.props.cost1 > this.props.resource1) {
      var redText1 = "redText"
    }

    if (this.props.cost2 > this.props.resource2) {
      var redText2 = "redText"
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-5">
            {techButton}
            <span className={redText1 || null}>
              <br />
              {this.props.cost1Text} Cost:{" "}
              {numeral(this.props.cost1).format(0, 0)}
            </span>
            <span className={redText2 || null}>
              <br />
              {this.props.cost2Text} Cost:{" "}
              {numeral(this.props.cost2).format(0, 0)}
            </span>
          </div>
          <div className="col-md-7">
            <span>
              <i>{this.props.description}</i>
            </span>
          </div>
        </div>
        <hr />
        <br />
      </div>
    );
  }
}

export default connect()(Technology);
