import React from "react";
import TradeButton from "../Components/TradeButton.js";
import {connect} from "react-redux";
var numeral = require("numeral"); //http://numeraljs.com/

class MarketSection extends React.Component {
  render() {
    return (
      <div>
        <ul className="list-group">
          <li className="list-group-item">
            <h3>Market</h3>
            <h5>Silver: <span className="badge badge-primary badge-pill">{numeral(this.props.resources.silver).format(0,0)}</span></h5>
            <TradeButton
              text="Food"
              resource={this.props.resources.food}
              cost={this.props.market.foodTradeCost}
              tradeValue={this.props.market.foodTradeValue}
              tradeAction="TRADE_FOOD_FOR_SILVER"
            />
            <TradeButton
              text="Wood"
              resource={this.props.resources.wood}
              cost={this.props.market.woodTradeCost}
              tradeValue={this.props.market.woodTradeValue}
              tradeAction="TRADE_WOOD_FOR_SILVER"
            />
            <TradeButton
              text="Stone"
              resource={this.props.resources.stone}
              cost={this.props.market.stoneTradeCost}
              tradeValue={this.props.market.stoneTradeValue}
              tradeAction="TRADE_STONE_FOR_SILVER"
            />
            <TradeButton
              text="Iron"
              resource={this.props.resources.iron}
              cost={this.props.market.ironTradeCost}
              tradeValue={this.props.market.ironTradeValue}
              tradeAction="TRADE_IRON_FOR_SILVER"
            />
            {this.props.copperMiningEnabled && (
              <TradeButton
                text="Copper"
                resource={this.props.resources.copper}
                cost={this.props.market.copperTradeCost}
                tradeValue={this.props.market.copperTradeValue}
                tradeAction="TRADE_COPPER_FOR_SILVER"
              />
            )}
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
 return {
    resources: state.resources,
    market: state.market,
    copperMiningEnabled: state.technology.copperMiningEnabled,
  }
}

export default connect(mapStateToProps)(MarketSection);
