import React from "react";
import {connect} from "react-redux";

var numeral = require("numeral"); //http://numeraljs.com/

class UpgradeButton extends React.Component {
  render() {
    if ( this.props.upgradeCost > this.props.silver ) {
      var upgradeButton = <button disabled className="btn btn-sm btn-secondary">{this.props.upgradeText}</button>;
      var redText = "redText";
    } else {
      // eslint-disable-next-line
      var upgradeButton = <button className="btn btn-sm btn-primary" onClick={() => this.props.dispatch({type: this.props.upgradeAction, upgrade: this.props.upgrade})}>{this.props.upgradeText}</button>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            {upgradeButton}
            <span><br />
              <i>{this.props.upgradeDesc}</i>
            </span>
            <span className={redText || null}>
              <br />
              Cost:{" "}{numeral(this.props.upgradeCost).format(0, 0)}{" "}{this.props.upgradeCostText} 
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(UpgradeButton);
