import React from "react";
//Components
import Nav from "../Sections/Nav.jsx";
import Dynasty from "../Sections/Dynasty.js";
import TempleSection from "../Sections/TempleSection.js";
import TechnologySection from "../Sections/TechnologySection.js";
import MarketSection from "../Sections/MarketSection.js";
import Resource from "./Resource.js";
import WordPress from "../Sections/WordPress.js";

//Redux
import {connect} from "react-redux";
import store from "../Redux/store";

//Others
// eslint-disable-next-line
//import $ from "jquery";
//import * as AddResources from "./Functions/addResources.js";


class Game extends React.Component {

  //////////////////////////////////
  /////// UPGRADE FUNCTIONS ///////
  //////////////////////////////////

woodUpgrade1 = () => {
    this.consumeSilver(this.state.woodUpgrade1Cost);
    this.setState({
        woodUpgrade1Visible: false,
        woodUpgrade2Visible: true,
        woodUpgrade1Enabled: true,
        woodMultiplier: this.state.woodMultiplier * 1.3,
    })
}

woodUpgrade2 = () => {
    this.consumeSilver(this.state.woodUpgrade2Cost);
    this.setState({
        woodUpgrade2Visible: false,
        woodUpgrade3Visible: true,
        woodUpgrade2Enabled: true,
        woodMultiplier: this.state.woodMultiplier * 1.5,
    })
}

woodUpgrade3 = () => {
    this.consumeSilver(this.state.woodUpgrade3Cost);
    this.setState({
        woodUpgrade3Visible: false,
        woodUpgradesCompletedTextVisible: true,
        woodUpgrade3Enabled: true,
        woodMultiplier: this.state.woodMultiplier * 2,
    })
}

stoneUpgrade1 = () => {
    this.consumeSilver(this.state.stoneUpgrade1Cost);
    this.setState({
        stoneUpgrade1Visible: false,
        stoneUpgrade2Visible: true,
        stoneUpgrade1Enabled: true,
        stoneMultiplier: this.state.stoneMultiplier * 1.3,
    })
}

stoneUpgrade2 = () => {
    this.consumeSilver(this.state.stoneUpgrade2Cost);
    this.setState({
        stoneUpgrade2Visible: false,
        stoneUpgrade3Visible: true,
        stoneUpgrade2Enabled: true,
        stoneMultiplier: this.state.stoneMultiplier * 1.5,
    })
}

stoneUpgrade3 = () => {
    this.consumeSilver(this.state.stoneUpgrade3Cost);
    this.setState({
        stoneUpgrade3Visible: false,
        stoneUpgradesCompletedTextVisible: true,
        stoneUpgrade3Enabled: true,
        stoneMultiplier: this.state.stoneMultiplier * 2,
    })
}

ironUpgrade1 = () => {
    this.consumeSilver(this.state.ironUpgrade1Cost);
    this.setState({
        ironUpgrade1Visible: false,
        ironUpgrade2Visible: true,
        ironUpgrade1Enabled: true,
        ironMultiplier: this.state.ironMultiplier * 1.3,
    })
}

ironUpgrade2 = () => {
    this.consumeSilver(this.state.ironUpgrade2Cost);
    this.setState({
        ironUpgrade2Visible: false,
        ironUpgrade3Visible: true,
        ironUpgrade2Enabled: true,
        ironMultiplier: this.state.ironMultiplier * 1.5,
    })
}

ironUpgrade3 = () => {
    this.consumeSilver(this.state.ironUpgrade3Cost);
    this.setState({
        ironUpgrade3Visible: false,
        ironUpgradesCompletedTextVisible: true,
        ironUpgrade3Enabled: true,
        ironMultiplier: this.state.ironMultiplier * 2,
    })
}

copperUpgrade1 = () => {
    this.consumeSilver(this.state.copperUpgrade1Cost);
    this.setState({
        copperUpgrade1Visible: false,
        copperUpgrade2Visible: true,
        copperUpgrade1Enabled: true,
        copperMultiplier: this.state.copperMultiplier * 1.3,
    })
}

copperUpgrade2 = () => {
    this.consumeSilver(this.state.copperUpgrade2Cost);
    this.setState({
        copperUpgrade2Visible: false,
        copperUpgrade3Visible: true,
        copperUpgrade2Enabled: true,
        copperMultiplier: this.state.copperMultiplier * 1.5,
    })
}

copperUpgrade3 = () => {
    this.consumeSilver(this.state.copperUpgrade3Cost);
    this.setState({
        copperUpgrade3Visible: false,
        copperUpgradesCompletedTextVisible: true,
        copperUpgrade3Enabled: true,
        copperMultiplier: this.state.copperMultiplier * 2,
    })
}

  //////////////////////////////////
  /////// SYSTEM FUNCTIONS ///////
  //////////////////////////////////

  autoSave = () => {
    localStorage.setItem("saveGame", window.btoa(JSON.stringify(store.getState())));
    console.log("Autosaved.");
  };

  deleteSave = () => {
    localStorage.removeItem("saveGame");
    window.location.reload(true);
  };

  componentDidMount() {
    setInterval(() => this.autoSave(), 5000);
    setInterval(() => this.props.dispatch({type: "CALCULATE_RESOURCES_PER_SECOND"}), 1000);
  }

  render() {
    return (
      <div>
        <Nav deleteSave={this.deleteSave}/>
        <div className="row">
          <div className="col-md-8">
            <div className="collapse multi-collapse show" id="resources">
              <ul className="list-group">
                <div className="collapse multi-collapse" id="dynasty">
                  <Dynasty />
                </div>
                <li className="list-group-item">
                  <h3>Resources</h3>
                  <Resource
                    //for Resource Component
                    resource="Food"
                    count={this.props.resources.food}
                    storage={this.props.resourceStorage.foodStorage}
                    multiplier={this.props.multipliers.foodMultiplier}
                    totalWorkers={this.props.workers.totalWorkers}
                    //for Resource and GetResourceButton
                    addResource="ADD_FOOD"
                    //for WorkerButton Component
                    worker="Farmer"
                    workers={this.props.workers.foodWorkers}
                    addWorker="ADD_FOOD_WORKER"
                    foodCost={this.props.workers.foodWorkerCost}
                    //for StorageButton Component
                    storageUpgrade="UPGRADE_FOOD_STORAGE"
                    //for UpgradeButton Component
                    upgradeAction="UPGRADE_FOOD_PRODUCTION"
                    upgrade1="1"
                    upgrade1Text="Animal Husbandry"
                    upgrade1Cost={this.props.upgrades.foodUpgrade1Cost}
                    upgrade1Desc="Breeding and cultivation of animal based agriculture."
                    upgrade1Visible={this.props.upgrades.foodUpgrade1Visible}
                    upgrade2="2"
                    upgrade2Text="Shaduf"
                    upgrade2Cost={this.props.upgrades.foodUpgrade2Cost}
                    upgrade2Desc="Hand operated irrigation device."
                    upgrade2Visible={this.props.upgrades.foodUpgrade2Visible}
                    upgrade3="3"
                    upgrade3Text="Basin Irrigation"
                    upgrade3Cost={this.props.upgrades.foodUpgrade3Cost}
                    upgrade3Desc="Network of earthen walls trapping the flood waters of the Nile."
                    upgrade3Visible={this.props.upgrades.foodUpgrade3Visible}
                    upgradesCompletedTextVisible={this.props.upgrades.foodUpgradesCompletedTextVisible}
                  />
                  <Resource
                    //for Resource Component
                    resource="Wood"
                    count={this.props.resources.wood}
                    storage={this.props.resourceStorage.woodStorage}
                    multiplier={this.props.multipliers.woodMultiplier}
                    //for Resource and GetResourceButton
                    addResource="ADD_WOOD"
                    //for WorkerButton Component
                    worker="Logger"
                    workers={this.props.workers.woodWorkers}
                    addWorker="ADD_WOOD_WORKER"
                    foodCost={this.props.workers.woodWorkerCost}
                    //for StorageButton Component
                    storageUpgrade="UPGRADE_WOOD_STORAGE"
                    //for UpgradeButton Component
                    upgradeAction="UPGRADE_WOOD_PRODUCTION"
                    upgrade1="1"
                    upgrade1Text="Stone Adze"
                    upgrade1Cost={this.props.upgrades.woodUpgrade1Cost}
                    upgrade1Desc="Simple hacking tool."
                    upgrade1Visible={this.props.upgrades.woodUpgrade1Visible}
                    upgrade2="2"
                    upgrade2Text="Copper Saw"
                    upgrade2Cost={this.props.upgrades.woodUpgrade1Cost}
                    upgrade2Desc="Hand cutting tool."
                    upgrade2Visible={this.props.upgrades.woodUpgrade2Visible}
                    upgrade="3"
                    upgrade3Text="Energised Saw"
                    upgrade3Cost={this.props.upgrades.woodUpgrade1Cost}
                    upgrade3Desc="Steel cutting tool empowered by electricity."
                    upgrade3Visible={this.props.upgrades.woodUpgrade3Visible}
                    upgradesCompletedTextVisible={this.props.upgrades.woodUpgradesCompletedTextVisible}
                  />
                  <Resource
                    //for Resource Component
                    resource="Stone"
                    count={this.props.resources.stone}
                    storage={this.props.resourceStorage.stoneStorage}
                    multiplier={this.props.multipliers.stoneMultiplier}
                    //for Resource and GetResourceButton
                    addResource="ADD_STONE"
                    //for WorkerButton Component
                    worker="Mason"
                    workers={this.props.workers.stoneWorkers}
                    addWorker="ADD_STONE_WORKER"
                    foodCost={this.props.workers.stoneWorkerCost}
                    //for StorageButton Component
                    storageUpgrade="UPGRADE_STONE_STORAGE"
                    //for UpgradeButton Component
                    upgradeAction="UPGRADE_STONE_PRODUCTION"
                    upgrade="1"
                    upgrade1Text="Copper Stone Saw"
                    upgrade1Cost={this.props.upgrades.stoneUpgrade1Cost}
                    upgrade1Desc="Simple hand cutting tool."
                    upgrade1Visible={this.props.upgrades.stoneUpgrade1Visible}
                    upgrade2="2"
                    upgrade2Text="Water Cutting"
                    upgrade2Cost={this.props.upgrades.stoneUpgrade2Cost}
                    upgrade2Desc="Hand cutting with pumped water."
                    upgrade2Visible={this.props.upgrades.stoneUpgrade2Visible}
                    upgrade3="3"
                    upgrade3Text="High Pressure Cutting"
                    upgrade3Cost={this.props.upgrades.stoneUpgrade3Cost}
                    upgrade3Desc="High pressure water cutting empowered by electricity."
                    upgrade3Visible={this.props.upgrades.stoneUpgrade3Visible}
                    upgradesCompletedTextVisible={this.props.upgrades.stoneUpgradesCompletedTextVisible}
                  />
                  <Resource
                    //for Resource Component
                    resource="Iron"
                    count={this.props.resources.iron}
                    storage={this.props.resourceStorage.ironStorage}
                    multiplier={this.props.multipliers.ironMultiplier}
                    //for Resource and GetResourceButton
                    addResource="ADD_IRON"
                    //for WorkerButton Component
                    worker="Miner"
                    workers={this.props.workers.ironWorkers}
                    addWorker="ADD_IRON_WORKER"
                    foodCost={this.props.workers.ironWorkerCost}
                    //for StorageButton Component
                    storageUpgrade="UPGRADE_IRON_STORAGE"
                    //for UpgradeButton Component
                    upgradeAction="UPGRADE_IRON_PRODUCTION"
                    upgrade1="1"
                    upgrade1Text="Open Pit Mining"
                    upgrade1Cost={this.props.upgrades.ironUpgrade1Cost}
                    upgrade1Desc="Mining method minimising underground excavation."
                    upgrade1Visible={this.props.upgrades.ironUpgrade1Visible}
                    upgrade2="2"
                    upgrade2Text="Thermal Shock Mining"
                    upgrade2Cost={this.props.upgrades.ironUpgrade2Cost}
                    upgrade2Desc="Intense heat applied to weaken rock."
                    upgrade2Visible={this.props.upgrades.ironUpgrade2Visible}
                    upgrade="3"
                    upgrade3Text="Drill Mining"
                    upgrade3Cost={this.props.upgrades.ironUpgrade3Cost}
                    upgrade3Desc="Steel drill empowered by electricity."
                    upgrade3Visible={this.props.upgrades.ironUpgrade3Visible}
                    upgradesCompletedTextVisible={this.props.upgrades.ironUpgradesCompletedTextVisible}

                  />
                  {this.props.technology.copperMiningEnabled && (
                    <Resource
                      //for Resource Component
                      resource="Copper"
                      count={this.props.resources.copper}
                      storage={this.props.resourceStorage.copperStorage}
                      multiplier={this.props.multipliers.copperMultiplier}
                      //for Resource and GetResourceButton
                      addResource="ADD_COPPER"
                      //for WorkerButton Component
                      worker="Miner"
                      workers={this.props.workers.copperWorkers}
                      addWorker="ADD_COPPER_WORKER"
                      foodCost={this.props.workers.copperWorkerCostFood}
                      ironCost={this.props.workers.copperWorkerCostIron}
                      //for StorageButton Component
                      storageUpgrade="UPGRADE_COPPER_STORAGE"
                      //for UpgradeButton Component
                      upgradeAction="UPGRADE_COPPER_PRODUCTION"
                      upgrade1="1"
                      upgrade1Text="Open Shaft Mining"
                      upgrade1Cost={this.props.upgrades.copperUpgrade1Cost}
                      upgrade1Desc="Mining method minimising underground excavation."
                      upgrade1Visible={this.props.upgrades.copperUpgrade1Visible}
                      upgrade2="2"
                      upgrade2Text="Thermal Shock Mining"
                      upgrade2Cost={this.props.upgrades.copperUpgrade2Cost}
                      upgrade2Desc="Intense heat applied to weaken rock."
                      upgrade2Visible={this.props.upgrades.copperUpgrade2Visible}
                      upgrade3="3"
                      upgrade3Text="Energised Drill"
                      upgrade3Cost={this.props.upgrades.copperUpgrade3Cost}
                      upgrade3Desc="Steel cutting drill empowered by electricity."
                      upgrade3Visible={this.props.upgrades.copperUpgrade3Visible}
                      upgradesCompletedTextVisible={this.props.upgrades.copperUpgradesCompletedTextVisible}

                    />
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="collapse multi-collapse show" id="technology">
              <TechnologySection />
            </div>
            <div className="collapse multi-collapse show" id="temple">
              <TempleSection />
            </div>
            <div className="collapse multi-collapse show" id="market">
              <MarketSection />
            </div>
            <div className="collapse multi-collapse show" id="wordpress">
              <WordPress />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    resources: state.resources,
    resourceStorage: state.resourceStorage,
    workers: state.workers,
    energy: state.energy,
    multipliers: state.multipliers,
    technology: state.technology,
    ui: state.ui,
    upgrades: state.upgrades,
    achievements: state.achievements,
    market: state.market,
  }
}

export default connect(mapStateToProps)(Game); 
