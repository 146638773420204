import React from "react";
import axios from "axios";

class WordPress extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pages: []
        };
    }

    getAllPages = async () => {
        let res = await axios.get(
            `http://wordpress.localhost:80/?rest_route=/wp/v2/pages`
        );
        let { data } = await res;

        this.setState({ pages: data });
    };

    componentDidMount = async () => {
        await this.getAllPages();
    };

    render() {
        const { pages } = this.state;

        return (
            <div>
                {
                    pages.map((page, index) => {
                        return <h1>{page.slug}</h1>;
                    })
                }
            </div>
        );
    }
}

export default WordPress;
