import React from "react";
import {connect} from "react-redux";

var numeral = require('numeral'); //http://numeraljs.com/

class WorkerButton extends React.Component {
  render() {
    
    // Set the Text to be displayed depending on the food availability
    if (this.props.foodCost > this.props.food) {
      var foodText = <span style={{ color: "red" }}>Food Cost: {numeral(this.props.foodCost).format(0,0)}</span>
    } else {// eslint-disable-next-line
      var foodText = <span>Food Cost: {numeral(this.props.foodCost).format(0,0)}</span>
    }

    // Set the Text to be displayed depending on the iron availability
    if (this.props.ironCost) {
      if (this.props.ironCost > this.props.iron) {
        var ironText = <span style={{ color: "red" }}><br/>Iron Cost: {numeral(this.props.ironCost).format(0,0)}</span>
      } else {// eslint-disable-next-line
        var ironText = <span><br/>Iron Cost: {numeral(this.props.ironCost).format(0,0)}</span>
      }
  }
    //if current food is less than the foodcost, disable the button
    if (this.props.foodCost > this.props.food || this.props.ironCost > this.props.iron) {
      return (
        <div>
          <button disabled className="btn btn-sm btn-secondary">
            Train {this.props.text}
          </button>
          <br />
          {foodText}
          {ironText}
        </div>
      );
      //else enable the button to create new Workers
    } else {
      return (
        //Add a worker and subtract its food cost
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => this.props.dispatch({type: this.props.addWorker})}
          >
            Train {this.props.text}
          </button>
          <br />
          {foodText}
          {ironText}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
   food: state.resources.food,
   iron: state.resources.iron,
  }
}

export default connect(mapStateToProps)(WorkerButton);
