import { createStore } from "redux";
import { combineReducers } from "redux";

/* //SaveGame Function
    if (localStorage.getItem("saveGame")) {
      var saveGame = JSON.parse(localStorage.getItem("saveGame"));
      //Take a copy of the state into a new Var to manipulate
      var baseState = this.state;
      // Merge object2 into object1
      $.extend(saveGame, baseState);
      // Import the new state
      this.state = saveGame;
      //console.log("Loading this saveGame:");
      //console.log(saveGame);
      //console.log("Successfully loaded a Save file from browser caches.");
      //console.log(this.state);
    } else {
      this.state = {};
    }
*/
const initialState = () => {
  if (localStorage.getItem("saveGame")) {
    var state = JSON.parse(window.atob(localStorage.getItem("saveGame")));
    return state;
  } else {
    // eslint-disable-next-line
    var state = {
      resources: {
        energy: 0,
        food: 150,
        wood: 50,
        iron: 0,
        stone: 0,
        copper: 0,
        steel: 0,
        silver: 0,
        gold: 0,
        addResourceDefault: 100,
      },
      resourceStorage: {
        energyStorage: 25,
        foodStorage: 150,
        ironStorage: 150,
        woodStorage: 150,
        stoneStorage: 150,
        copperStorage: 150,
        steelStorage: 150,
      },
      workers: {
        //Workers
        totalWorkers: 0,
        foodWorkers: 0,
        ironWorkers: 0,
        woodWorkers: 0,
        stoneWorkers: 0,
        copperWorkers: 0,
        steelWorkers: 0,
        //Food Costs
        foodWorkerCost: 25,
        ironWorkerCost: 50,
        woodWorkerCost: 50,
        stoneWorkerCost: 50,
        copperWorkerCostFood: 50,
        copperWorkerCostIron: 100,
      },
      energy: {
        energyProduction: 0,
        energyConsumption: 0,
        parthianBatteryCostCopper: 100,
        parthianBatteryCostIron: 100,
      },
      multipliers: {
        globalMultiplier: 1,
        energyMultiplier: 1,
        foodMultiplier: 2,
        ironMultiplier: 1,
        woodMultiplier: 1,
        stoneMultiplier: 1,
        copperMultiplier: 1,
        steelMultiplier: 1,
      },
      technology: {
        //Technology button visibility in the Technology Section
        copperMiningVisible: true,
        parthianBatteryVisible: false,
        pyrolysisVisible: true,
        blastFurnaceVisible: false,
        //Technology Research Costs
        copperMiningResearchCostFood: 1500,
        copperMiningResearchCostIron: 1200,
        parthianBatteryResearchCostCopper: 2500,
        parthianBatteryResearchCostIron: 1600,
        pyrolysisResearchCostWood: 1800,
        pyrolysisResearchCostStone: 2400,
        blastFurnaceResearchCostIron: 2750,
        blastFurnaceResearchCostStone: 3200,
        //Technology Enabled
        copperMiningEnabled: false, //enables Copper Resource for Energy related things (conductivity)
        parthianBatteryEnabled: false, //enables Energy related things
        pyrolysisEnabled: false, //enables Charcoal usage (consume Wood)
        blastFurnaceEnabled: false, //enables Steel related things
        //Technology Descriptions
        copperMiningDesc:
          "Advanced mining and smelting methods. Unlocks Parthian Batteries.",
        parthianBatteryDesc:
          "Parthian Batteries generate a small electrical charge. The application of which is yet unknown...",
        pyrolysisDesc:
          "The process of irreversible and simultaneous physical and chemical change of organic materials. Unlocks the ability to use Wood as Charcoal.",
        blastFurnaceDesc:
          "Metallurgical smelting of Iron Ore will allow for the creation of new, stronger alloys.",
      },
      ui: {
        technologyEnabled: true, //Technology Section enabled in the Nav
        templeEnabled: true,
        marketEnabled: true,
      },
      upgrades: {
        //Upgrade Visibility
        foodUpgrade1Visible: true,
        foodUpgrade2Visible: false,
        foodUpgrade3Visible: false,
        foodUpgradesCompletedTextVisible: false,
        woodUpgrade1Visible: true,
        woodUpgrade2Visible: false,
        woodUpgrade3Visible: false,
        woodUpgradesCompletedTextVisible: false,
        stoneUpgrade1Visible: true,
        stoneUpgrade2Visible: false,
        stoneUpgrade3Visible: false,
        stoneUpgradesCompletedTextVisible: false,
        ironUpgrade1Visible: true,
        ironUpgrade2Visible: false,
        ironUpgrade3Visible: false,
        ironUpgradesCompletedTextVisible: false,
        copperUpgrade1Visible: true,
        copperUpgrade2Visible: false,
        copperUpgrade3Visible: false,
        copperUpgradesCompletedTextVisible: false,
        //Upgrade Enabled
        foodUpgrade1Enabled: false,
        foodUpgrade2Enabled: false,
        foodUpgrade3Enabled: false,
        woodUpgrade1Enabled: false,
        woodUpgrade2Enabled: false,
        woodUpgrade3Enabled: false,
        stoneUpgrade1Enabled: false,
        stoneUpgrade2Enabled: false,
        stoneUpgrade3Enabled: false,
        ironUpgrade1Enabled: false,
        ironUpgrade2Enabled: false,
        ironUpgrade3Enabled: false,
        copperUpgrade1Enabled: false,
        copperUgrade2Enabled: false,
        copperUpgrade3Enabled: false,
        //Upgrade Costs
        foodUpgrade1Cost: 900,
        foodUpgrade2Cost: 1700,
        foodUpgrade3Cost: 2600,
        woodUpgrade1Cost: 1200,
        woodUpgrade2Cost: 2100,
        woodUpgrade3Cost: 3200,
        stoneUpgrade1Cost: 1400,
        stoneUpgrade2Cost: 2500,
        stoneUpgrade3Cost: 3300,
        ironUpgrade1Cost: 1350,
        ironUpgrade2Cost: 2200,
        ironUpgrade3Cost: 3250,
        copperUpgrade1Cost: 1700,
        copperUpgrade2Cost: 2650,
        copperUpgrade3Cost: 3400,
      },
      achievements: {
        foodAchievement1: 1000,
        foodAchievement2: 10000,
        foodAchievement1Done: false,
        foodAchievement2Done: false,
      },
      market: {
        foodTradeValue: 5,
        foodTradeCost: 1000,
        woodTradeValue: 4,
        woodTradeCost: 1000,
        stoneTradeValue: 5,
        stoneTradeCost: 650,
        ironTradeValue: 6,
        ironTradeCost: 650,
        copperTradeValue: 8,
        copperTradeCost: 300,
      },
    };
    return state;
  }
};

function resourcesReducer(state = initialState(), action) {
  switch (action.type) {
    case "ADD_FOOD":
      // if the added resources go over the storage amount, cap the resource at the storage limit
      var newFood =
        state.resources.food + action.add >= state.resourceStorage.foodStorage
          ? state.resourceStorage.foodStorage + state.workers.totalWorkers
          : state.resources.food + action.add;
      return {
        ...state,
        resources: {
          ...state.resources,
          food: newFood,
        },
      };
    case "ADD_WOOD":
      // if the added resources go over the storage amount, cap the resource at the storage limit
      var newWood =
        state.resources.wood + action.add >= state.resourceStorage.woodStorage
          ? state.resourceStorage.woodStorage
          : state.resources.wood + action.add;
      return {
        ...state,
        resources: {
          ...state.resources,
          wood: newWood,
        },
      };
    case "ADD_STONE":
      // if the added resources go over the storage amount, cap the resource at the storage limit
      var newStone =
        state.resources.stone + action.add >= state.resourceStorage.stoneStorage
          ? state.resourceStorage.stoneStorage
          : state.resources.stone + action.add;
      return {
        ...state,
        resources: {
          ...state.resources,
          stone: newStone,
        },
      };
    case "ADD_IRON":
      // if the added resources go over the storage amount, cap the resource at the storage limit
      var newIron =
        state.resources.iron + action.add >= state.resourceStorage.ironStorage
          ? state.resourceStorage.ironStorage
          : state.resources.iron + action.add;
      return {
        ...state,
        resources: {
          ...state.resources,
          iron: newIron,
        },
      };
    case "ADD_COPPER":
      // if the added resources go over the storage amount, cap the resource at the storage limit
      var newCopper =
        state.resources.copper + action.add >=
        state.resourceStorage.copperStorage
          ? state.resourceStorage.copperStorage
          : state.resources.copper + action.add;
      return {
        ...state,
        resources: {
          ...state.resources,
          copper: newCopper,
        },
      };
    case "CALCULATE_RESOURCES_PER_SECOND":
      if (state.resources.food >= state.workers.totalWorkers) {
        // prettier-ignore
        var foodProductionMinusConsumption = state.resources.food + (state.workers.foodWorkers * (state.multipliers.foodMultiplier + state.multipliers.globalMultiplier) - state.workers.totalWorkers);
        var foodStorage = state.resourceStorage.foodStorage;
        // eslint-disable-next-line
        const newFood = foodProductionMinusConsumption <= foodStorage ? foodProductionMinusConsumption : foodStorage;

        // eslint-disable-next-line
        const newWood =
          state.resources.wood +
            state.workers.woodWorkers *
              (state.multipliers.woodMultiplier +
                state.multipliers.globalMultiplier) <=
          state.resourceStorage.woodStorage
            ? state.resources.wood +
              state.workers.woodWorkers *
                (state.multipliers.woodMultiplier +
                  state.multipliers.globalMultiplier)
            : state.resourceStorage.woodStorage;

        // eslint-disable-next-line
        const newStone =
          state.resources.stone +
            state.workers.stoneWorkers *
              (state.multipliers.stoneMultiplier +
                state.multipliers.globalMultiplier) <=
          state.resourceStorage.stoneStorage
            ? state.resources.stone +
              state.workers.stoneWorkers *
                (state.multipliers.stoneMultiplier +
                  state.multipliers.globalMultiplier)
            : state.resourceStorage.stoneStorage;

        // eslint-disable-next-line
        const newIron =
          state.resources.iron +
            state.workers.ironWorkers *
              (state.multipliers.ironMultiplier +
                state.multipliers.globalMultiplier) <=
          state.resourceStorage.ironStorage
            ? state.resources.iron +
              state.workers.ironWorkers *
                (state.multipliers.ironMultiplier +
                  state.multipliers.globalMultiplier)
            : state.resourceStorage.ironStorage;

        // eslint-disable-next-line
        const newCopper =
          state.resources.copper +
            state.workers.copperWorkers *
              (state.multipliers.copperMultiplier +
                state.multipliers.globalMultiplier) <=
          state.resourceStorage.copperStorage
            ? state.resources.copper +
              state.workers.copperWorkers *
                (state.multipliers.copperMultiplier +
                  state.multipliers.globalMultiplier)
            : state.resourceStorage.copperStorage;

        return {
          ...state,
          resources: {
            ...state.resources,
            food: newFood,
            wood: newWood,
            stone: newStone,
            iron: newIron,
            copper: newCopper,
          },
        };
      } else {
        return state;
      }

    case "RESEARCH_COPPER_MINING":
      return {
        ...state,
        resources: {
          ...state.resources,
          food:
            state.resources.food -
            state.technology.copperMiningResearchCostFood,
          iron:
            state.resources.iron -
            state.technology.copperMiningResearchCostIron,
        },
        technology: {
          ...state.technology,
          copperMiningVisible: false,
          copperMiningEnabled: true,
          parthianBatteryVisible: true,
        },
      };
    case "RESEARCH_PARTHIAN_BATTERY":
      return {
        ...state,
        resources: {
          ...state.resources,
          copper:
            state.resources.copper -
            state.technology.parthianBatteryResearchCostCopper,
          iron:
            state.resources.iron -
            state.technology.parthianBatteryResearchCostIron,
        },
        technology: {
          ...state.technology,
          parthianBatteryVisible: false,
          parthianBatteryEnabled: true,
        },
      };
    case "RESEARCH_PYROLYSIS":
      return {
        ...state,
        resources: {
          ...state.resources,
          wood:
            state.resources.wood - state.technology.pyrolysisResearchCostWood,
          stone:
            state.resources.stone - state.technology.pyrolysisResearchCostStone,
        },
        technology: {
          ...state.technology,
          pyrolysisVisible: false,
          pyrolysisEnabled: true,
          blastFurnaceVisible: true,
        },
      };
    case "RESEARCH_BLAST_FURNACE":
      return {
        ...state,
        resources: {
          ...state.resources,
          iron:
            state.resources.iron -
            state.technology.blastFurnaceResearchCostIron,
          stone:
            state.resources.stone -
            state.technology.blastFurnaceResearchCostStone,
        },
        technology: {
          ...state.technology,
          blastFurnaceVisible: false,
          blastFurnaceEnabled: true,
        },
      };
    case "UPGRADE_FOOD_STORAGE":
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food * 0.1,
        },
        resourceStorage: {
          ...state.resourceStorage,
          foodStorage: Math.round(state.resourceStorage.foodStorage * 2),
        },
      };
    case "UPGRADE_WOOD_STORAGE":
      return {
        ...state,
        resources: {
          ...state.resources,
          wood: state.resources.wood * 0.1,
        },
        resourceStorage: {
          ...state.resourceStorage,
          woodStorage: Math.round(state.resourceStorage.woodStorage * 2),
        },
      };
    case "UPGRADE_IRON_STORAGE":
      return {
        ...state,
        resources: {
          ...state.resources,
          iron: state.resources.iron * 0.1,
        },
        resourceStorage: {
          ...state.resourceStorage,
          ironStorage: Math.round(state.resourceStorage.ironStorage * 2),
        },
      };
    case "UPGRADE_STONE_STORAGE":
      return {
        ...state,
        resources: {
          ...state.resources,
          stone: state.resources.stone * 0.1,
        },
        resourceStorage: {
          ...state.resourceStorage,
          stoneStorage: Math.round(state.resourceStorage.stoneStorage * 2),
        },
      };
    case "UPGRADE_COPPER_STORAGE":
      return {
        ...state,
        resources: {
          ...state.resources,
          copper: state.resources.copper * 0.1,
        },
        resourceStorage: {
          ...state.resourceStorage,
          copperStorage: Math.round(state.resourceStorage.copperStorage * 2),
        },
      };
    case "ADD_FOOD_WORKER":
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food - state.workers.foodWorkerCost,
        },
        workers: {
          ...state.workers,
          foodWorkers: state.workers.foodWorkers + 1,
          totalWorkers: state.workers.totalWorkers + 1,
          foodWorkerCost: Math.round(
            state.workers.foodWorkerCost + state.workers.foodWorkers
          ),
        },
      };
    case "ADD_WOOD_WORKER":
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food - state.workers.woodWorkerCost,
        },
        workers: {
          ...state.workers,
          woodWorkers: state.workers.woodWorkers + 1,
          totalWorkers: state.workers.totalWorkers + 1,
          woodWorkerCost: Math.round(
            state.workers.woodWorkerCost + state.workers.woodWorkers
          ),
        },
      };
    case "ADD_IRON_WORKER":
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food - state.workers.ironWorkerCost,
        },
        workers: {
          ...state.workers,
          ironWorkers: state.workers.ironWorkers + 1,
          totalWorkers: state.workers.totalWorkers + 1,
          ironWorkerCost: Math.round(
            state.workers.ironWorkerCost + state.workers.ironWorkers
          ),
        },
      };
    case "ADD_STONE_WORKER":
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food - state.workers.stoneWorkerCost,
        },
        workers: {
          ...state.workers,
          stoneWorkers: state.workers.stoneWorkers + 1,
          totalWorkers: state.workers.totalWorkers + 1,
          stoneWorkerCost: Math.round(
            state.workers.stoneWorkerCost + state.workers.stoneWorkers
          ),
        },
      };
    case "ADD_COPPER_WORKER":
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food - state.workers.copperWorkerCost,
        },
        workers: {
          ...state.workers,
          copperWorkers: state.workers.copperWorkers + 1,
          totalWorkers: state.workers.totalWorkers + 1,
          copperWorkerCost: Math.round(
            state.workers.copperWorkerCost + state.workers.copperWorkers
          ),
        },
      };
    case "CONSUME_FOOD":
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food - action.value,
        },
      };
    // CONSUME WOOD, IRON, STONE, COPPER, SILVER //
    case "TRADE_FOOD_FOR_SILVER":
      // eslint-disable-next-line
      var newTradeValue =
        state.market.foodTradeValue <= 1 ? 1 : state.market.foodTradeValue - 1; //foodTradeValue shouldn't go below 1
      return {
        ...state,
        resources: {
          ...state.resources,
          food: state.resources.food - state.market.foodTradeCost,
          silver: state.resources.silver + state.market.foodTradeValue,
        },
        market: {
          ...state.market,
          foodTradeCost: Math.round(state.market.foodTradeCost * 1.1),
          foodTradeValue: newTradeValue,
          woodTradeValue: state.market.woodTradeValue + 1,
          stoneTradeValue: state.market.stoneTradeValue + 1,
          ironTradeValue: state.market.ironTradeValue + 1,
          copperTradeValue: state.market.copperTradeValue + 1,
        },
      };
    case "TRADE_WOOD_FOR_SILVER":
      // eslint-disable-next-line
      var newTradeValue =
        state.market.woodTradeValue <= 1 ? 1 : state.market.woodTradeValue - 1; //woodTradeValue shouldn't go below 1
      return {
        ...state,
        resources: {
          ...state.resources,
          wood: state.resources.wood - state.market.woodTradeCost,
          silver: state.resources.silver + state.market.woodTradeValue,
        },
        market: {
          ...state.market,
          woodTradeCost: Math.round(state.market.woodTradeCost * 1.1),
          foodTradeValue: state.market.foodTradeValue + 1,
          woodTradeValue: newTradeValue,
          stoneTradeValue: state.market.stoneTradeValue + 1,
          ironTradeValue: state.market.ironTradeValue + 1,
          copperTradeValue: state.market.copperTradeValue + 1,
        },
      };
    case "TRADE_STONE_FOR_SILVER":
      // eslint-disable-next-line
      var newTradeValue =
        state.market.stoneTradeValue <= 1
          ? 1
          : state.market.stoneTradeValue - 1; //stoneTradeValue shouldn't go below 1
      return {
        ...state,
        resources: {
          ...state.resources,
          stone: state.resources.stone - state.market.stoneTradeCost,
          silver: state.resources.silver + state.market.stoneTradeValue,
        },
        market: {
          ...state.market,
          stoneTradeCost: Math.round(state.market.stoneTradeCost * 1.1),
          foodTradeValue: state.market.foodTradeValue + 1,
          woodTradeValue: state.market.woodTradeValue + 1,
          stoneTradeValue: newTradeValue,
          ironTradeValue: state.market.ironTradeValue + 1,
          copperTradeValue: state.market.copperTradeValue + 1,
        },
      };
    case "TRADE_IRON_FOR_SILVER":
      // eslint-disable-next-line
      var newTradeValue =
        state.market.ironTradeValue <= 1 ? 1 : state.market.ironTradeValue - 1; //ironTradeValue shouldn't go below 1
      return {
        ...state,
        resources: {
          ...state.resources,
          iron: state.resources.iron - state.market.ironTradeCost,
          silver: state.resources.silver + state.market.ironTradeValue,
        },
        market: {
          ...state.market,
          ironTradeCost: Math.round(state.market.ironTradeCost * 1.1),
          foodTradeValue: state.market.foodTradeValue + 1,
          woodTradeValue: state.market.woodTradeValue + 1,
          stoneTradeValue: state.market.stoneTradeValue + 1,
          ironTradeValue: newTradeValue,
          copperTradeValue: state.market.copperTradeValue + 1,
        },
      };
    case "TRADE_COPPER_FOR_SILVER":
      // eslint-disable-next-line
      var newTradeValue =
        state.market.copperTradeValue <= 1
          ? 1
          : state.market.copperTradeValue - 1; //copperTradeValue shouldn't go below 1
      return {
        ...state,
        resources: {
          ...state.resources,
          copper: state.resources.copper - state.market.copperTradeCost,
          silver: state.resources.silver + state.market.copperTradeValue,
        },
        market: {
          ...state.market,
          copperTradeCost: Math.round(state.market.copperTradeCost * 1.1),
          foodTradeValue: state.market.foodTradeValue + 1,
          woodTradeValue: state.market.woodTradeValue + 1,
          stoneTradeValue: state.market.stoneTradeValue + 1,
          ironTradeValue: newTradeValue,
          copperTradeValue: state.market.copperTradeValue + 1,
        },
      };
    case "UPGRADE_FOOD_PRODUCTION":
      switch (action.upgrade) {
        case "1":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.foodUpgrade1Cost,
            },
            multipliers: {
              ...state.multipliers,
              foodMultiplier: Math.round(
                state.multipliers.foodMultiplier * 1.3
              ),
            },
            upgrades: {
              ...state.upgrades,
              foodUpgrade1Visible: false,
              foodUpgrade2Visible: true,
              foodUpgrade1Enabled: true,
            },
          };
        case "2":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.foodUpgrade2Cost,
            },
            multipliers: {
              ...state.multipliers,
              foodMultiplier: Math.round(
                state.multipliers.foodMultiplier * 1.5
              ),
            },
            upgrades: {
              ...state.upgrades,
              foodUpgrade2Visible: false,
              foodUpgrade3Visible: true,
              foodUpgrade2Enabled: true,
            },
          };
        case "3":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.foodUpgrade3Cost,
            },
            multipliers: {
              ...state.multipliers,
              foodMultiplier: Math.round(state.multipliers.foodMultiplier * 2),
            },
            upgrades: {
              ...state.upgrades,
              foodUpgrade3Visible: false,
              foodUpgrade3Enabled: true,
              foodUpgradesCompletedTextVisible: true,
            },
          };
        default:
          return state;
      }
    case "UPGRADE_WOOD_PRODUCTION":
      switch (action.upgrade) {
        case "1":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.woodUpgrade1Cost,
            },
            multipliers: {
              ...state.multipliers,
              woodMultiplier: Math.round(
                state.multipliers.woodMultiplier * 1.3
              ),
            },
            upgrades: {
              ...state.upgrades,
              woodUpgrade1Visible: false,
              woodUpgrade2Visible: true,
              woodUpgrade1Enabled: true,
            },
          };
        case "2":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.woodUpgrade2Cost,
            },
            multipliers: {
              ...state.multipliers,
              woodMultiplier: Math.round(
                state.multipliers.woodMultiplier * 1.5
              ),
            },
            upgrades: {
              ...state.upgrades,
              woodUpgrade2Visible: false,
              woodUpgrade3Visible: true,
              woodUpgrade2Enabled: true,
            },
          };
        case "3":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.woodUpgrade3Cost,
            },
            multipliers: {
              ...state.multipliers,
              woodMultiplier: Math.round(state.multipliers.woodMultiplier * 2),
            },
            upgrades: {
              ...state.upgrades,
              woodUpgrade3Visible: false,
              woodUpgrade3Enabled: true,
              woodUpgradesCompletedTextVisible: true,
            },
          };
        default:
          return state;
      }
    case "UPGRADE_STONE_PRODUCTION":
      switch (action.upgrade) {
        case "1":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.stoneUpgrade1Cost,
            },
            multipliers: {
              ...state.multipliers,
              stoneMultiplier: Math.round(
                state.multipliers.stoneMultiplier * 1.3
              ),
            },
            upgrades: {
              ...state.upgrades,
              stoneUpgrade1Visible: false,
              stoneUpgrade2Visible: true,
              stoneUpgrade1Enabled: true,
            },
          };
        case "2":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.stoneUpgrade2Cost,
            },
            multipliers: {
              ...state.multipliers,
              stoneMultiplier: Math.round(
                state.multipliers.stoneMultiplier * 1.5
              ),
            },
            upgrades: {
              ...state.upgrades,
              stoneUpgrade2Visible: false,
              stoneUpgrade3Visible: true,
              stoneUpgrade2Enabled: true,
            },
          };
        case "3":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.stoneUpgrade3Cost,
            },
            multipliers: {
              ...state.multipliers,
              stoneMultiplier: Math.round(
                state.multipliers.stoneMultiplier * 2
              ),
            },
            upgrades: {
              ...state.upgrades,
              stoneUpgrade3Visible: false,
              stoneUpgrade3Enabled: true,
              stoneUpgradesCompletedTextVisible: true,
            },
          };
        default:
          return state;
      }
    case "UPGRADE_IRON_PRODUCTION":
      switch (action.upgrade) {
        case "1":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.ironUpgrade1Cost,
            },
            multipliers: {
              ...state.multipliers,
              ironMultiplier: Math.round(
                state.multipliers.ironMultiplier * 1.3
              ),
            },
            upgrades: {
              ...state.upgrades,
              ironUpgrade1Visible: false,
              ironUpgrade2Visible: true,
              ironUpgrade1Enabled: true,
            },
          };
        case "2":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.ironUpgrade2Cost,
            },
            multipliers: {
              ...state.multipliers,
              ironMultiplier: Math.round(
                state.multipliers.ironMultiplier * 1.5
              ),
            },
            upgrades: {
              ...state.upgrades,
              ironUpgrade2Visible: false,
              ironUpgrade3Visible: true,
              ironUpgrade2Enabled: true,
            },
          };
        case "3":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver: state.resources.silver - state.upgrades.ironUpgrade3Cost,
            },
            multipliers: {
              ...state.multipliers,
              ironMultiplier: Math.round(state.multipliers.ironMultiplier * 2),
            },
            upgrades: {
              ...state.upgrades,
              ironUpgrade3Visible: false,
              ironUpgrade3Enabled: true,
              ironUpgradesCompletedTextVisible: true,
            },
          };
        default:
          return state;
      }
    case "UPGRADE_COPPER_PRODUCTION":
      switch (action.upgrade) {
        case "1":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver:
                state.resources.silver - state.upgrades.copperUpgrade1Cost,
            },
            multipliers: {
              ...state.multipliers,
              copperMultiplier: Math.round(
                state.multipliers.copperMultiplier * 1.3
              ),
            },
            upgrades: {
              ...state.upgrades,
              copperUpgrade1Visible: false,
              copperUpgrade2Visible: true,
              copperUpgrade1Enabled: true,
            },
          };
        case "2":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver:
                state.resources.silver - state.upgrades.copperUpgrade2Cost,
            },
            multipliers: {
              ...state.multipliers,
              copperMultiplier: Math.round(
                state.multipliers.copperMultiplier * 1.5
              ),
            },
            upgrades: {
              ...state.upgrades,
              copperUpgrade2Visible: false,
              copperUpgrade3Visible: true,
              copperUpgrade2Enabled: true,
            },
          };
        case "3":
          return {
            ...state,
            resources: {
              ...state.resources,
              silver:
                state.resources.silver - state.upgrades.copperUpgrade3Cost,
            },
            multipliers: {
              ...state.multipliers,
              copperMultiplier: Math.round(
                state.multipliers.copperMultiplier * 2
              ),
            },
            upgrades: {
              ...state.upgrades,
              copperUpgrade3Visible: false,
              copperUpgrade3Enabled: true,
              copperUpgradesCompletedTextVisible: true,
            },
          };
        default:
          return state;
      }
    default:
      return state;
  }
}

function resourceStorageReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function workersReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function energyReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function technologyReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function multipliersReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function uiReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function upgradesReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function achievementsReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

function marketReducer(state = initialState.workers, action) {
  switch (action.type) {
    default:
      return state;
  }
}

// eslint-disable-next-line
const gameReducer = combineReducers({
  resourcesReducer,
  resourceStorageReducer,
  workersReducer,
  energyReducer,
  technologyReducer,
  multipliersReducer,
  uiReducer,
  upgradesReducer,
  achievementsReducer,
  marketReducer,
});

export default createStore(resourcesReducer);
