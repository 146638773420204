import React from "react";
import Technology from "../Components/Technology.js";
import {connect} from "react-redux";

class TechnologySection extends React.Component {
  render() {
    return (
      <div>
        <ul className="list-group">
          <li className="list-group-item">
            <h3>Technology</h3>
            {this.props.technology.copperMiningVisible && ( //if true, display the technology
              <Technology
                text="Copper Mining"
                resource1={this.props.food}
                resource2={this.props.iron}
                researchTech={() => this.props.dispatch({type: "RESEARCH_COPPER_MINING"})}
                description={this.props.technology.copperMiningDesc}
                cost1={this.props.technology.copperMiningResearchCostFood}
                cost1Text="Food"
                cost2={this.props.technology.copperMiningResearchCostIron}
                cost2Text="Iron"
              />
            )}
            {this.props.technology.parthianBatteryVisible && ( //if true, display the technology
              <Technology
                text="Parthian Battery"
                resource1={this.props.iron}
                resource2={this.props.copper}
                researchTech={() => this.props.dispatch({type: "RESEARCH_PARTHIAN_BATTERY"})}
                description={this.props.technology.parthianBatteryDesc}
                cost1={this.props.technology.parthianBatteryResearchCostIron}
                cost1Text="Iron"
                cost2={this.props.technology.parthianBatteryResearchCostCopper}
                cost2Text="Copper"
              />
            )}
            {this.props.technology.pyrolysisVisible && ( //if true, display the technology
              <Technology
                text="Pyrolysis"
                resource1={this.props.wood}
                resource2={this.props.stone}
                researchTech={() => this.props.dispatch({type: "RESEARCH_PYROLYSIS"})}
                description={this.props.technology.pyrolysisDesc}
                cost1={this.props.technology.pyrolysisResearchCostWood}
                cost1Text="Wood"
                cost2={this.props.technology.pyrolysisResearchCostStone}
                cost2Text="Stone"
              />
            )}
            {this.props.technology.blastFurnaceVisible && ( //if true, display the technology
              <Technology
                text="Blast Furnace"
                resource1={this.props.iron}
                resource2={this.props.stone}
                researchTech={() => this.props.dispatch({type: "RESEARCH_BLAST_FURNACE"})}
                description={this.props.technology.blastFurnaceDesc}
                cost1={this.props.technology.blastFurnaceResearchCostIron}
                cost1Text="Iron"
                cost2={this.props.technology.blastFurnaceResearchCostStone}
                cost2Text="Stone"
              />
            )}
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    food: state.resources.food,
    wood: state.resources.wood,
    iron: state.resources.iron,
    stone: state.resources.stone,
    copper: state.resources.copper,
    technology: state.technology,
  }
}

export default connect(mapStateToProps)(TechnologySection);
