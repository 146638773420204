import React from "react";
import {connect} from "react-redux";

var numeral = require('numeral'); //http://numeraljs.com/

class StorageButton extends React.Component {
  render() {
    //if current resource storage is full, enable upgrade
    if (this.props.count <= (this.props.storage * 0.9)) {
      return (
        <div>
          <button className="btn btn-sm btn-secondary">
            Upgrade Storage
          </button>
          <br />
          <span style={{ color: "red" }}>{this.props.resource} Cost: {numeral(this.props.storage * 0.9).format(0,0)}</span>
        </div>
      );
      //else enable the button to upgrade the storage
    } else {
      return (
        //Add a worker and subtract its resource cost
        <div>
        <button className="btn btn-sm btn-primary"
            onClick={() => {this.props.dispatch({type: this.props.storageUpgrade})}}
                >
            Upgrade Storage
        </button>
        <br />
        <span>{this.props.resource} Cost: {numeral(this.props.storage * 0.9).format(0,0)}</span>
        </div>
      );
    }
  }
}

export default connect()(StorageButton);
