import React from "react";
import {connect} from "react-redux";

class TradeButton extends React.Component {
  render() {
    if ( this.props.resource < this.props.cost ) {
      var tradeButton = <button disabled className="btn btn-sm btn-secondary">Trade {this.props.text}</button>;
      var redText = "redText";
    } else {
      // eslint-disable-next-line
      var tradeButton = <button className="btn btn-sm btn-primary" onClick={() => this.props.dispatch({type: this.props.tradeAction,cost: this.props.cost})}>Trade {this.props.text}</button>;
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            {tradeButton}
            <span className={redText || null}><br />
              <i>Trade {this.props.cost} {this.props.text} for {this.props.tradeValue} Silver</i>
            </span>
          </div>
        </div>
        <hr />
        <br />
      </div>
    );
  }
}

export default connect()(TradeButton);
