import React from "react";
import {connect} from "react-redux";

class Nav extends React.Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        {/* eslint-disable-next-line*/}
        <a className="navbar-brand">Ancient Egypt</a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a
                className="nav-link"
                href="#resources"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="resources"
              >
                Resources
              </a>
            </li>
            {this.props.technologyEnabled && ( //if true, display the toggle button
            <li className="nav-item">
              <a
                className="nav-link"
                href="#technology"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="technology"
              >
                Technology
              </a>
            </li>
            )}
            {this.props.templeEnabled && ( //if true, display the toggle button
            <li className="nav-item">
              <a
                className="nav-link"
                href="#temple"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="temple"
              >
                Temple
              </a>
            </li>
            )}
            {this.props.marketEnabled && ( //if true, display the toggle button
            <li className="nav-item">
              <a
                className="nav-link"
                href="#market"
                data-toggle="collapse"
                aria-expanded="false"
                aria-controls="market"
              >
                Market
              </a>
            </li>
            )}
            <li className="nav-item dropdown">
              {/* eslint-disable-next-line*/}
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Other
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a
                  className="dropdown-item"
                  href="#dynasty"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="dynasty"
                >
                  Dynasty
                </a>
                {/* eslint-disable-next-line*/}
                <a
                  className="dropdown-item"
                  href="#"
                  data-target=".multi-collapse"
                  data-toggle="collapse"
                  aria-expanded="false"
                  aria-controls="multiCollapse1 multiCollapse2"
                >
                  Toggle All
                </a>
                {/* eslint-disable-next-line*/}
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => {
                    this.props.deleteSave();
                  }}
                >
                  Delete SaveGame
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    technologyEnabled: state.ui.technologyEnabled,
    templeEnabled: state.ui.templeEnabled,
    marketEnabled: state.ui.marketEnabled,
  }
}

export default connect(mapStateToProps)(Nav);
