import React from "react";

class TempleSection extends React.Component {
  render() {
    return (
      <div>
        <ul className="list-group">
          <li className="list-group-item">
            <h3>Temple of Thoth</h3>
          </li>
        </ul>
      </div>
    );
  }
}

export default TempleSection;
