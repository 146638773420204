import React from "react";

class Dynasty extends React.Component {
  render() {
    return (
      <div>
        <ul className="list-group">
          <li className="list-group-item">
          <h3>Dynasty</h3>

            <p>
              The Sahara is drying, driving our people East towards the Nile. We
              have been without sufficient food, water and shelter for far too
              long. We have brought with us all the food and tools we could
              carry but have little in the way of building materials to
              construct our homes.
            </p>
            <p>
              We have encountered a bearded stranger from a far land he names
              Gobleki Tepe, carrying a satchel of knowledge, secrets, and wisdom
              much beyond our comprehension. The stranger has begun to teach our
              people to produce food without dependance on the roaming herds.
              This knowledge of <i>Agriculture</i> can build us to new heights.
            </p>
            <p>
              With the aid of our new teacher, perhaps we will once more live as
              our great ancestors did before the <i>Time of Ice</i> and{" "}
              <i>The Great Cataclysm of Water</i> that has so ravaged the land
              and our peoples.
            </p>
          </li>
        </ul>
      </div>
    );
  }
}

export default Dynasty;
