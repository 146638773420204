import React from "react";
import GetResourceButton from "./GetResourceButton.js";
import WorkerButton from "./WorkerButton.js";
import StorageButton from "./StorageButton.js";
import UpgradeButton from "./UpgradeButton.js";
import {connect} from "react-redux";
var numeral = require("numeral"); //http://numeraljs.com/

class Resource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //prettier-ignore
    if (this.props.resource === "Food" && (this.props.foodProduction - this.props.foodConsumption) >= this.props.count) {
        var badgeWarning = "badge badge-warning badge-pill";
    } else {
      if (this.props.count >= this.props.storage) {
        // eslint-disable-next-line
        var badgeWarning = "badge badge-warning badge-pill";
      } else {
        var badgePrimary = "badge badge-primary badge-pill";
      }
    }


      // prettier-ignore
    if (this.props.workers * (this.props.multiplier + this.props.globalMultiplier) - (this.props.totalWorkers || 0) < 0) {
      var productionBadgePositive = "badge badge-danger badge-pill";
    } else {
      // eslint-disable-next-line
      var productionBadgeNegative = "badge badge-primary badge-pill";
    }

    return (
      <ul className="list-group list-group-horizontal">
        <li className="list-group-item" style={{ width: "25%" }}>
          <h5>{this.props.resource}</h5>
          <span>
            <b>{this.props.resource}</b>:{" "}
            <span className={badgePrimary || badgeWarning}>
              {" "}
              {/*Total amount of the Resource currently stored*/}
              {numeral(Math.round(this.props.count)).format(0, 0)} /{" "}
              {numeral(this.props.storage).format(0, 0)}
            </span>
          </span>
          <br />
          <span>
            <b>{this.props.resource} Workers</b>:{" "}
            <span className="badge badge-primary badge-pill">
              {" "}
              {/*Total number of workers for the Resource*/}
              {this.props.workers}
            </span>{" "}
            <br />
            <b>Production /sec</b>:{" "}
            <span
              className={productionBadgePositive || productionBadgeNegative}
            >
              {numeral(this.props.workers * (this.props.multiplier + this.props.globalMultiplier) - (this.props.totalWorkers || 0)).format(0,0)}{" "}
              {/*Total Resource being produced per second*/}
            </span>
            <br />
          </span>
        </li>

        <li className="list-group-item" style={{ width: "25%" }}>
          <h5>Production</h5>
          <GetResourceButton
            text={this.props.resource}
            addResource={this.props.addResource}
          />
          <WorkerButton
            text={this.props.worker}
            addWorker={this.props.addWorker}
            foodCost={this.props.foodCost}
            ironCost={this.props.ironCost}
          />
        </li>
        <li className="list-group-item" style={{ width: "25%" }}>
          <h5>Storage</h5>
          <StorageButton
            resource={this.props.resource}
            count={this.props.count}
            storage={this.props.storage}
            storageUpgrade={this.props.storageUpgrade}
          />
        </li>
        <li className="list-group-item" style={{ width: "25%" }}>
          <h5>Upgrades</h5>
          {this.props.upgrade1Visible &&
          <UpgradeButton
            upgradeAction = {this.props.upgradeAction}
            upgrade = {this.props.upgrade1}
            upgradeText = {this.props.upgrade1Text}
            upgradeCost = {this.props.upgrade1Cost}
            upgradeDesc = {this.props.upgrade1Desc}
            upgradeHandler = {this.props.upgrade1Handler}
            upgradeCostText = "Silver"
          />
        }
          {this.props.upgrade2Visible &&
          <UpgradeButton
            upgradeAction = {this.props.upgradeAction}
            upgrade = {this.props.upgrade2}
            upgradeText = {this.props.upgrade2Text}
            upgradeCost = {this.props.upgrade2Cost}
            upgradeDesc = {this.props.upgrade2Desc}
            upgradeHandler = {this.props.upgrade2Handler}
            upgradeCostText = "Silver"
          />
        }
          {this.props.upgrade3Visible &&
          <UpgradeButton
            upgradeAction = {this.props.upgradeAction}
            upgrade = {this.props.upgrade3}
            upgradeText = {this.props.upgrade3Text}
            upgradeCost = {this.props.upgrade3Cost}
            upgradeDesc = {this.props.upgrade3Desc}   
            upgradeHandler = {this.props.upgrade3Handler}
            upgradeCostText = "Silver"
          />
        }
          {this.props.upgradesCompletedTextVisible &&
          <i>All {this.props.resource} Upgrades are fully researched.</i>
          }
        </li>
      </ul>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    globalMultiplier: state.multipliers.globalMultiplier,
    resources: state.resources,
    foodConsumption: state.workers.totalWorkers,
    foodProduction: state.workers.foodWorkers * (state.multipliers.foodMultiplier + state.multipliers.globalMultiplier),
    foodWorkers: state.workers.foodWorkers,
    foodMultiplier: state.multipliers.foodMultiplier,
  }
}
export default connect(mapStateToProps)(Resource);
