import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

//imported from old ------------------------------------
//Redux
import { Provider } from "react-redux";
import store from "./Redux/store";
//Application
import GameContainer from "./Components/GameContainer.js";

//React Render
const root = document.getElementById("root");

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <GameContainer />
    </Provider>,
    root
  );
};

store.subscribe(render);
render();
//imported from old ------------------------------------

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
